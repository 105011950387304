import { useEffect, useState } from "react";
import { Table, message, Button, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as API from "@api";

dayjs.extend(relativeTime);

export default function DataTable({
  showHeader = true,
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true,
  compact = false,
}) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current
      ? parseInt(searchParams.pagination.current)
      : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      pagination: {
        current: params.current,
      },
    });

    const _res = await API.Settings.getAllPages({
      filter: filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Ошибка при запросе данных, попробуйте позже", 2);
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const columns = [
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Страница",
      width: 300,
      render: (_, data) => (
        <Link to={`/settings/pages/edit/${data.type}`}>
          <span className="block text-base font-medium text-black leading-tight mb-1">
            <Tag className="m-0">
              {data?.type === "page:home"
                ? "Главная"
                : "/" + data?.type?.split(":")[1]}
            </Tag>
          </span>
        </Link>
      ),
    },
    {
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      title: "Посл. изменение",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data, all) => (
        <div>
          <span className="block text-sm font-normal text-neutral-400">
            {dayjs(data).fromNow()} {all?.user?.name && `${all?.user?.name}`}
          </span>
          <span className="block text-sm font-medium text-black">
            {dayjs(data).format("DD.MM.YYYY HH:mm")}
          </span>
        </div>
      ),
      sorter: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Link to={`/settings/pages/edit/${all.type}`}>
          <Button icon={<EditOutlined />}>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="type"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
